
.news-details {

    &__date {
        font-size: 20px;
        font-weight: map-get($font-weight, black);
        line-height: 1;
    }

    &__headline {
        margin: .5em 0 .5em !important;
    }

    &__author {
        a {
            font-size: 18px;
            text-decoration: none;
        }
    }

    &__categories {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            margin: 0;
            padding: 0;
        }

        a {
            font-size: 18px;
            text-decoration: none;

            &:before {
                content: "#";
            }
        }
    }

    &__social {
        margin: 25px 0 60px;
        padding: 0;
        list-style-type: none;

        &-item {
            display: inline;
            margin: 0 30px 0 0;
            padding: 0;
        }

        &-icon {
            width: 20px;
            height: 20px;
            @include social-icons;
        }
    }

    &__text {

        &-inside {
            hyphens: auto;
            border-top: 2px solid $text-color;
        }

        .ce_text:first-child {
            margin-bottom: 2em;
            font-size: 23px;
            line-height: 1.3;
            @include font-regular;
        }
    }

    @include screen(medium) {
        &__header {
            text-align: right;
        }

        &__social {
            margin: 60px 0 0;
            text-align: right;

            &-item {
                margin: 0 0 0 30px;
            }

            &-icon {
                width: 15px;
                height: 15px;
            }
        }

        &__text {
            &-inside {
                padding-top: 18px;
            }
        }
    }
}
