@import "fonts";

html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: none;

    html[lang=it] & {
        text-align: justify;
    }
}

input,
button,
textarea,
select {
    margin: 0;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    font-size: 99%;
    border-radius: 0;
}

textarea,
button,
input[type="email"],
input[type="tel"],
input[type="text"],
input[type="submit"],
input[type="search"] {
    -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

textarea[disabled],
button[disabled],
input[type="email"][disabled],
input[type="tel"][disabled],
input[type="text"][disabled],
input[type="submit"][disabled],
input[type="search"][disabled] {
    cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type="checkbox"][disabled] + label,
input[type="radio"][disabled] + label {
    cursor: not-allowed;
}

table {
    //width: 100%;
    font-size: inherit;
    border-collapse: collapse;
    border-spacing: 0;
}

table td, table th {
    padding: 4px 15px;
    vertical-align: top;
}

tr > :first-child {
    padding-left: 0;
}

th {
    font-weight: 700;
}

caption,
strong,
b {
    font-weight: 700;
}

i,
em {
    font-weight: 400;
}

ul {
    margin: 0 0 -5px;
    padding: 0 0 0 1em;
}

li {
    margin: 0 0 5px;
    padding: 0;
    line-height: 1.4;
}

ul ul {
    margin-bottom: 0;
}

form,
figure,
blockquote {
    margin: 0;
    padding: 0;
}

img,
iframe {
    max-width: 100%;
    height: auto;
    border: none;
    vertical-align: middle;
    border: 0;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption {
    display: block;
}

html {
    font-size: 16px;
    height: 100%;
}

body {
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100%;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);

    color: $text-color;
    font-family: neuzeit-grotesk, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.57;

    @include screen(medium) {
        font-size: 16px;
        line-height: 1.75;
    }
}

h1, h2, h3, h4, h5, h6, p {
    margin: 1.5em 0;
    font-size: inherit;
    font-weight: inherit;
}

a {
    color: $link-color;
    text-decoration: underline;
}

i {
    font-style: normal;
}

b,
strong {
    font-weight: map-get($font-weight, bold);
}

caption,
th,
td {
    text-align: start;
}

abbr,
acronym {
    font-variant: normal;
    border-bottom: 1px dotted #3c3c3c;
    cursor: help;
}

blockquote,
q {
    quotes: none;
}

sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

del {
    text-decoration: line-through;
}

ins {
    text-decoration: none;
}

pre,
code {
    font-family: monospace;
}

/* Vertical align */
.float_left {
    float: left;
}

.float_right {
    float: right;
}

/* Clear floats */
.clear,
#clear {
    height: 0.1px;
    font-size: 0.1px;
    line-height: 0.1px;
    clear: both;
}

/* Hide invisible elements */
.invisible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

.mod_navigation,
.mod_changelanguage,
.mod_breadcrumb,
.mod_team_topics,
.mod_topic_team,
.page-navigation,
.news-navigation,
.ce_gallery {
    ul, li {
        margin: 0;
        padding: 0;
        list-style-type: none;
        line-height: initial;
    }
}

@keyframes postit-corner {
    0% {
        opacity: 0;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
